
import Vue from 'vue';
import BillLayout from '@/layouts/BillLayout.vue';
import FormLayout from '@/layouts/FormLayout.vue';
import TableLayout from '@/layouts/TableLayout.vue';
import ColumnLayout from '@/layouts/ColumnLayout.vue';
import SkeletonGradient from '@/components/loading/SkeletonGradient.vue';
import formTitle from '@/assets/data/formTitle/index.json';
export default Vue.extend({
  name: 'NimsStep01SkeletonTemplate',
  components: { BillLayout, FormLayout, TableLayout, ColumnLayout, SkeletonGradient },
  data() {
    return {
      formTitle,
      btnHeight: '35px',
      infoText: '140px',
      termText: '340px',
    };
  },
});
