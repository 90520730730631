
import Vue, { PropType } from 'vue';
import { getDateStringFromFormat } from '@/utils/common.js';
import { NORMAL_PREPAYMENT_OPTION, ADDITIONAL_GOODS_MODAL_IDS } from '@/assets/data/nims/constants';
import { NIMS_EQUIP_DEFAULT } from '@/assets/data/nims/constants';
import { NimsEquipment } from '@/api/infra-renew/types';
import BillLayout from '@/layouts/BillLayout.vue';
import formTitle from '@/assets/data/formTitle/index.json';
import FormLayout from '@/layouts/FormLayout.vue';
import TableLayout from '@/layouts/TableLayout.vue';
import ColumnLayout from '@/layouts/ColumnLayout.vue';
import Step01Modals from './fragments/Step01Modals.vue';
import { isEmptyObject, deepCopyObject } from '@/utils/common';
import { fitExpireDate } from '@gabia/gabia-order-helper';

export default Vue.extend({
  name: 'NimsStep01TechnicalTemplate',
  components: { BillLayout, FormLayout, TableLayout, ColumnLayout, Step01Modals },
  model: { prop: 'formState', event: 'change' },
  props: {
    formState: {
      type: Object,
      default() {
        return {
          period: '1',
          paymentType: '',
          chargeMethod: '', // D : 후납
          groupExpireDate: '',
          pakageName: '',
          techHelpGoods: [] as string[],
          terms: {
            agree1: false,
            agree2: false,
            agree3: false,
          },
        };
      },
    },
    currentEquipment: {
      type: Object as PropType<NimsEquipment>,
      default: () => {
        return NIMS_EQUIP_DEFAULT;
      },
    },
  },
  data() {
    return {
      package_id: '',
      agreeAll: false,
      isCtTypeSelected: false,
      settlementInfo: {
        stfId: 0,
        buId: 0,
        os: 'L',
        startDate: '',
      },
      additionalModalIds: ADDITIONAL_GOODS_MODAL_IDS,
      paymentOption: [{ value: '', label: '' }] as Array<{ value: string; label: string }>,
      formTitle,
    };
  },
  computed: {
    isLinuxAvaliable(): boolean {
      return !isEmptyObject(this.formState.additionalLinuxGoods);
    },
    isWindowAvaliable(): boolean {
      return !isEmptyObject(this.formState.additionalWindowGoods);
    },
    agreeBoth(): boolean {
      return !!(this.formState.terms.agree1 && this.formState.terms.agree2 && this.formState.terms.agree3);
    },
    isGroupExist(): boolean {
      return this.$route.query.group || this.formState.groupExpireDate;
    },
    currentDate(): string {
      return getDateStringFromFormat('yyyy-mm-dd');
    },
    calculateDaysBetweenDatesHasNotGroup(): number {
      if (!this.settlementInfo.startDate) {
        return 0;
      }

      const d1 = new Date(this.settlementInfo.startDate) as any;
      const d2 = new Date(this.limitDate) as any;

      // 날짜 차이 계산 (밀리초 단위)
      const timeDiff = d2 - d1;

      // 밀리초를 일로 변환 (1일 = 24시간 * 60분 * 60초 * 1000밀리초)
      const daysDiff = timeDiff / (24 * 60 * 60 * 1000);

      return Math.abs(daysDiff); // 절대값을 반환하여 음수 결과 방지
    },

    calculateDaysBetweenDatesHasGroup(): number {
      if (!this.settlementInfo.startDate) {
        return 0;
      }

      const d1 = new Date(this.settlementInfo.startDate) as any;
      const d2 = new Date(this.formState.groupExpireDate) as any;

      // 날짜 차이 계산 (밀리초 단위)
      const timeDiff = d2 - d1;

      // 밀리초를 일로 변환 (1일 = 24시간 * 60분 * 60초 * 1000밀리초)
      const daysDiff = timeDiff / (24 * 60 * 60 * 1000);

      return Math.abs(daysDiff); // 절대값을 반환하여 음수 결과 방지
    },

    contractLimitDate(): string {
      if (!this.settlementInfo.startDate) {
        return '';
      }

      const limitYear = parseInt(this.settlementInfo.startDate.substring(0, 4)) + 2;
      const limitMonthDate = this.settlementInfo.startDate.substring(4);

      return `${limitYear}${limitMonthDate}`;
    },
    limitDate(): string {
      /*
       *  1 ~ 15 일까지는 그달의 말일 ex) 2022-12-02 -> 2022-12-31
       *  16 ~ 31 일까지는 다음달의 말일 ex)  2022-12-22 -> 2023-01-31
       *
       */

      if (!this.settlementInfo.startDate) {
        return '';
      }

      this.$emit('limit-date', fitExpireDate(this.settlementInfo.startDate, this.formState.period));

      return fitExpireDate(this.settlementInfo.startDate, this.formState.period);
    },
  },
  watch: {
    agreeBoth(newValue) {
      this.agreeAll = newValue;
    },
  },
  mounted() {
    this.package_id = this.$route.query.package as string;
    this.settlementInfo.startDate = this.formState.startDate;
    this.settlementInfo.stfId = this.formState.stfId;
    this.settlementInfo.buId = this.formState.buId;
    this.paymentOption = NORMAL_PREPAYMENT_OPTION;
  },
  methods: {
    addTechHelp(payload, index: number) {
      const refId = `tech-select_${index}`;

      if (this.$refs[refId] !== undefined && this.$refs[refId] !== null) {
        const selectBox = (this.$refs[refId] as HTMLSelectElement[])[0];

        if (selectBox) {
          const duplicateCheck = this.formState.techHelpGoods.filter((item) => {
            return item.goods_id === selectBox.value;
          });

          const selectedGood = payload.filter((item) => {
            return item.goods_id === parseInt(selectBox.value);
          });

          if (duplicateCheck.length) {
            alert('중복 신청입니다.');
            return;
          }

          const tempArr = [
            ...this.formState.techHelpGoods,
            {
              goods_id: selectBox.value,
              group_name: selectedGood[0].goods_group.expose_name,
              goods_name: selectedGood[0].goods.expose_name,
            },
          ];
          this.onChageFormState('techHelpGoods', tempArr);
        }
      }
    },
    removeTmpTechHelp(goods_id: string) {
      const tempArr = this.formState.techHelpGoods.filter((item) => {
        return item.goods_id !== goods_id;
      });
      this.onChageFormState('techHelpGoods', tempArr);
    },
    onChageFormState(property_name, value) {
      this.$emit('change', {
        ...this.formState,
        [property_name]: value,
      });
      this.$emit('calculate-price');
    },
    getIdc(item: NimsEquipment) {
      return item.idc?.label || '-';
    },
    onChangeAgreeAll(value) {
      if (value) {
        this.$emit('change', {
          ...this.formState,
          terms: {
            agree1: true,
            agree2: true,
            agree3: true,
          },
        });
      } else {
        this.$emit('change', {
          ...this.formState,
          terms: {
            agree1: false,
            agree2: false,
            agree3: false,
          },
        });
      }
    },
    onChangeTerms(e) {
      const { name, checked } = e;

      const params = {
        ...this.formState,
        terms: {
          ...this.formState.terms,
          [`agree${name}`]: checked,
        },
      };

      if (this.formState.chargeMethod !== 'D') {
        params.terms.agree3 = true;
      }

      this.$emit('change', params);
    },
    openModal(modal_name) {
      this.$GabiaModal.show(modal_name);
    },
    onChangeStartDate(event) {
      const { value } = event.target;
      this.$emit('change', {
        ...this.formState,
        startDate: String(value),
      });
      this.$emit('calculate-price');
    },
    isAddtionalGoodsHasModal(modal_id: string) {
      return this.additionalModalIds.includes(modal_id);
    },
  },
});
