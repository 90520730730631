import { NimsAdditionalGoods } from '@/types/common/goods';

export const GoodsAssembleFnc = (arr) => {
  const returnObj = arr.reduce((acc, cur) => {
    const k = cur.sort_order || 1;
    if (!acc[k]) acc[k] = [];
    acc[k].push(cur);
    return acc;
  }, {});
  return returnObj;
};

export const GoodsAssembleAdditionalGoodsFnc = (arr): NimsAdditionalGoods => {
  const returnObj = arr.reduce((acc, cur) => {
    const k = cur.sort_order;
    if (!acc[k]) {
      acc[k] = {
        list: [],
        checked: false,
        checkboxDisabled: false,
        selectedId: cur.goods_id,
        modalId: `${cur.goods.goods_type.code.toLowerCase()}-modal`,
        goodsGroup: { ...cur.goods_group, code: cur.goods.goods_type.code },
      };
    }
    acc[k].list.push(cur);
    return acc;
  }, {});
  return returnObj;
};

export const setCheckboxDisabledFnc = (goods: NimsAdditionalGoods, disabledCodeArr: string[]): NimsAdditionalGoods => {
  for (const [, item] of Object.entries(goods)) {
    if (disabledCodeArr.includes(item.goodsGroup.code)) {
      item.checked = true;
      item.checkboxDisabled = true;
    }
  }
  return goods;
};
