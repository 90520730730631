
import Vue from 'vue';
import { getGoodsInfo } from '@/api/goods';
import {
  getGoodsPriceExpiredSpecUseProxy,
  createNimsTechOrderProxy,
  deleteNimsOrderProxy,
  getNimsOrderInfoProxy,
} from '@/api/gabia-proxy';
import { nimsGoodsTypeStructureGenerator } from '@/utils/common';
import { getGroupInfo, getGroupEquipmentInfo, createTechHelpInfra } from '@/api/infra-renew/index';
import { GoodsPriceInfo } from '@/api/goods/types';
import { getDateStringFromFormat } from '@/utils/common.js';
import { NIMS_TECHNICAL_GOODS } from '@/assets/data/nims/constants';
import { GoodsAssembleFnc } from '@/assets/data/nims/helper';
import CalculatorScreen from '@/components/common/CalculatorScreen/CalculatorScreen.vue';
import NimsStep01TechnicalTemplate from '@/templates/nims/NimsStep01TechnicalTemplate.vue';
import NimsStep01SkeletonTemplate from '@/templates/nims/NimsStep01SkeletonTemplate.vue';
import { GoodsPayType } from '@/assets/data/nims/constants';
import { moveToBillingPage } from '@/utils';
import { NimsGoodsHostingInfo } from '@/api/gabia-proxy/types';
import { NimsEquipment } from '@/api/infra-renew/types';

const NIMS_APPLY_ERROR = 'NIMS_APPLY_ERROR';
const GOODS_ORDER_APPLY_ERROR = 'GOODS_ORDER_APPLY_ERROR';

export default Vue.extend({
  name: 'NimsStep01TechBrain',
  components: { CalculatorScreen, NimsStep01TechnicalTemplate, NimsStep01SkeletonTemplate },
  props: {},
  data() {
    return {
      isRender: false,
      formState: {
        pakageName: '',
        mainGoods: {},
        terms: {
          agree1: false,
          agree2: false,
          agree3: false,
        },
        techHelpGoods: [] as Array<{ goods_id: string; goods_name: string; group_name: string }>,
        paymentType: 'PP' as GoodsPayType, // 추후 API가 오면 값을 새로 맞출것
        period: '1',
        startDate: '',
        os: 'L',
        chargeMethod: '', // D : 후납
        groupExpireDate: '',
      },
      currentEquipment: {} as NimsEquipment,
      package_id: '',
      equipmentSeqNo: '',
      order_number: '',
      order_seqno: 0,
      selectedGoodsPriceInfo: {
        totalPrice: 0 as number | undefined,
        totalPriceWithVat: 0 as number | undefined,
        vat: 0 as number | undefined,
        selectedGoods: [] as GoodsPriceInfo[],
        totalRulePrice: 0 as number | undefined,
        cutPrice: 0 as number | undefined,
      },

      nimsHostingInfo: {} as NimsGoodsHostingInfo,
      createGroupInfo: {
        prosessFlag: true,
        seqNo: 0,
        serviceSeqno: 0,
        settlementObj: {
          settlement_id: '',
        },
      },

      groupSeqNo: '',
      limitDate: '',
    };
  },
  computed: {
    userId(): string {
      return this.$store.state.userInfo?.user_id ?? '';
    },
  },
  watch: {},
  async mounted() {
    this.package_id = this.$route.query.package as string;
    this.equipmentSeqNo = this.$route.query.equipment_seq as string;
    this.formState.startDate = getDateStringFromFormat('yyyy-mm-dd');
    this.formState.paymentType = (this.$route.query?.p_method as GoodsPayType) || 'PA';
    this.groupSeqNo = this.$route.query?.group ? String(this.$route.query?.group) : '';

    if (this.groupSeqNo) {
      await this.getGroupInfoFnc();
      await this.getTechHelp();
    }

    if (NIMS_TECHNICAL_GOODS[this.package_id]) {
      await this.getPakageInfo();
      await this.getPriceInfoForExpireUseProxy();
    } else {
      alert('허용되지 않은 상품조회 입니다.');
    }
  },
  methods: {
    async getTechHelp() {
      try {
        const { data } = await getGroupEquipmentInfo(this.groupSeqNo, this.equipmentSeqNo);
        this.currentEquipment = data.data;
      } catch (error) {
        console.log(error);
      }
    },
    async getGroupInfoFnc() {
      try {
        const { data } = await getGroupInfo(parseInt(this.groupSeqNo));
        this.formState.chargeMethod =
          data.data.service.pay_type.code === 'DA' || data.data.service.pay_type.code === 'DP' ? 'D' : 'P';
        this.formState.groupExpireDate = data.data.group_expire_date
          ? data.data.group_expire_date.substring(0, 10)
          : '';
      } catch (error) {
        console.warn(error);
      }
    },
    async goNextStep() {
      if (!this.formState.terms.agree1 || !this.formState.terms.agree2) {
        alert('모든 약관에 동의해주세요.');
        window.scroll({
          top: document.body.scrollHeight,
          behavior: 'smooth',
        });
        return;
      }

      if (!this.selectedGoodsPriceInfo.selectedGoods.length) {
        alert('선택된 상품이 없습니다.');
        return;
      }

      this.$GlobalLoading.show();
      try {
        await this.createOrder();
        await this.createTechHelpInfraFnc();
      } catch (error) {
        const errorObj = error as Error;
        const errorCode = errorObj.message;

        if (errorCode === NIMS_APPLY_ERROR) {
          alert('상품신청에서 오류가 발생했습니다.');
          await this.deleteOrder();
        } else if (errorCode === GOODS_ORDER_APPLY_ERROR) {
          alert('주문서 생성에서 오류가 발생했습니다.');
          await this.deleteOrder();
        }

        this.$GlobalLoading.hide();
      }
    },
    async getGroupOrderInfo() {
      if (!this.createGroupInfo.prosessFlag) {
        alert('error getGroupOrderInfo');
        return;
      }

      const params = {
        uri: `/v5/payment/${this.createGroupInfo.seqNo}`,
      };
      try {
        const { data } = await getNimsOrderInfoProxy(params);
        console.log(data);
        this.createGroupInfo.settlementObj.settlement_id = String(data.settlement_list[0].shistory_rowid);
        console.log(this.createGroupInfo);
      } catch (error) {
        this.createGroupInfo.prosessFlag = false;
        console.log(error);
      }
    },
    async deleteOrder() {
      const params = {
        uri: '/mygabia/payment/order',
        data: {
          user_id: this.userId,
          ordernum_list: this.order_number,
        },
      };

      try {
        const response = await deleteNimsOrderProxy(params);
        console.log(response);
      } catch (error) {
        console.log(error);
        alert('주문서 취소에 실패했습니다.');
      }
    },
    async createOrder() {
      const params = {
        uri: '/v5/custom/order',
        data: {
          user_id: this.userId,
          checkout: {
            settlement_list:
              this.selectedGoodsPriceInfo?.selectedGoods?.map((goods) => {
                return {
                  service: {
                    seqno: this.groupSeqNo,
                  },
                  settlement_option: {
                    dep_item: 'IMS_TS',
                    service_seqno: this.equipmentSeqNo,
                  },
                  domain: this.groupSeqNo,
                  order_term: 1,
                  goods: {
                    id: goods.goods_id,
                    quantity: goods.quantity,
                  },
                };
              }) || [],
            checkout_option: {
              origin: 'gabia',
            },
            total_price: this.selectedGoodsPriceInfo.totalPriceWithVat,
          },
        },
      };

      try {
        const { data } = await createNimsTechOrderProxy(params);
        this.order_number = data.order_number;
        this.order_seqno = data.seqno;
      } catch (error) {
        console.log(error);
        throw new Error(GOODS_ORDER_APPLY_ERROR);
      }
    },
    async createTechHelpInfraFnc() {
      try {
        await createTechHelpInfra(this.groupSeqNo, this.equipmentSeqNo, String(this.order_seqno));
        const params = {
          ordernum: this.order_number,
          return_url: `${process.env.VUE_APP_APPLICATION_URL}/apply-step-03/nims?hst_no=${this.order_seqno}&type=tech`,
          back_url: `${process.env.VUE_APP_APPLICATION_URL}/apply-step-01/nims?package=${this.package_id}&group=${this.groupSeqNo}&equipment_seq=${this.equipmentSeqNo}`,
        };

        if (process.env.VUE_APP_MODE === 'development') {
          alert(
            `결제 페이지로 이동합니다.  3단계 hst_no=${this.order_seqno}  * 해당메세지는 develop 에서만 나옵니다. - 개발디버깅 용`,
          );
        }

        moveToBillingPage(params);
      } catch (error) {
        console.log(error);
        throw new Error(NIMS_APPLY_ERROR);
      }
    },

    async getPakageInfo() {
      const params = {
        'options[only_sales_goods]': 'N',
        'package_list[0][id]': this.package_id,
      };

      try {
        const goodsInfo = await getGoodsInfo(params);

        if (goodsInfo?.package_list) {
          this.formState.pakageName = goodsInfo.package_list[0].expose_name;
          //! api 가 첫 호출시 캐싱을 진행하여 요청 순번대로 오지 않는경우가 발생했으나 해당 경우는 백엔드에서 처리가 어렵다는 이야기를 전달받아 프론트에서 재처리
          const tmp_main_goods = goodsInfo.package_list.filter((item) => String(item.id) === this.package_id);

          this.formState.mainGoods = tmp_main_goods.length
            ? GoodsAssembleFnc(tmp_main_goods[0].package_goods_list)
            : {};

          this.isRender = true;
        }
      } catch (error) {
        console.log(error);
      }
    },
    async getPriceInfoForExpireUseProxy() {
      this.$GlobalLoading.show();

      const goodsIdArr = this.formState.techHelpGoods.map((item) => {
        return item.goods_id;
      });
      const orderTerm = parseInt(this.formState.period);

      if (!goodsIdArr.length) {
        this.$GlobalLoading.hide();

        this.selectedGoodsPriceInfo = {
          totalPrice: 0,
          totalPriceWithVat: 0,
          vat: 0,
          selectedGoods: [],
          totalRulePrice: 0,
          cutPrice: 0,
        };

        return;
      }

      const goodsPriceParams = {
        uri: '/v4/goods/pricing/calculate',
      };

      goodsIdArr.forEach((goodsId, idx) => {
        goodsPriceParams[`data[goodsList[${idx}][quantity]]`] = 1;
        goodsPriceParams[`data[goodsList[${idx}][id]]`] = goodsId;
        goodsPriceParams[`data[goodsList[${idx}][order_term]]`] = orderTerm;

        if (!orderTerm) {
          goodsPriceParams[`data[goodsList[${idx}][start_date]]`] = this.formState.startDate;
        }
      });
      goodsPriceParams[`data[order_situation]`] = 'application';
      const { data } = await getGoodsPriceExpiredSpecUseProxy(goodsPriceParams);

      this.selectedGoodsPriceInfo = nimsGoodsTypeStructureGenerator(data, {
        carve_code: 'NIMS',
        carve_code_str: 'NIMS',
      });

      this.selectedGoodsPriceInfo = {
        totalPrice: data?.total_pricing?.total_price || 0,
        totalPriceWithVat: data?.total_pricing.total_price_with_vat || 0,
        vat: data?.total_pricing.vat || 0,
        selectedGoods: data?.goods_pricing_list || [],
        totalRulePrice: data?.total_pricing.total_rule_price || 0,
        cutPrice: data?.total_pricing.cut_price || 0,
      };
      this.$GlobalLoading.hide();
    },
  },
});
