
import Vue from 'vue';

export default Vue.extend({
  name: 'SkeletonGradient',
  props: {
    width: {
      type: String,
      default: '70px',
    },
    height: {
      type: String,
      default: '30px',
    },
  },
});
